<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 77%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    background: #1bc5bd;
    color: white;
    border-radius: 3px;
  }
}
</style>
<template>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card form-body">
        <div class="card-body">
          <form action="">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for=""
                    >Company <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="company_name"
                    :class="
                      errors['company_name'] ? 'border border-danger' : ''
                    "
                  />
                  <span v-if="errors['company_name']" class="text-danger">{{
                    errors["company_name"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Contact Person </label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="contact_name"
                    :class="
                      errors['contact_name'] ? 'border border-danger' : ''
                    "
                  />
                  <span v-if="errors['contact_name']" class="text-danger">{{
                    errors["contact_name"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Email <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="email"
                    :class="errors['email'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['email']" class="text-danger">{{
                    errors["email"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Contact Phone no </label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-mask="'(###)-###-####'"
                    v-model="contact_phone_no"
                    :class="
                      errors['contact_phone_no'] ? 'border border-danger' : ''
                    "
                  />
                  <span v-if="errors['contact_phone_no']" class="text-danger">{{
                    errors["contact_phone_no"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Address Line 1</label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="address1"
                    :class="errors['address1'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['address1']" class="text-danger">{{
                    errors["address1"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Address Line 2 </label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="address2"
                    :class="errors['address2'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['address2']" class="text-danger">{{
                    errors["address2"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">City </label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="city"
                    :class="errors['city'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['city']" class="text-danger">{{
                    errors["city"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">State </label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="state"
                    :class="errors['state'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['state']" class="text-danger">{{
                    errors["state"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Zipcode </label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="zipcode"
                    :class="errors['zipcode'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['zipcode']" class="text-danger">{{
                    errors["zipcode"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Country </label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="country"
                    :class="errors['country'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['country']" class="text-danger">{{
                    errors["country"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">Select Address</label>
                  <keep-alive>
                    <GoogleMap
                      ref="clientmap"
                      @getFullAddress="setFullAddress"
                      @dragPointer="setLocation"
                      @onSearchClick="setLocation"
                    ></GoogleMap>
                  </keep-alive>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="card-footer">
          <button
            v-if="isEdit"
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
          >
            <i class="fa fa-check"></i>Update
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success ml-3"
            style="float: right"
            @click="store('kt_save_service', 'S')"
            ref="kt_save_service"
          >
            <i class="fa fa-check"></i>Save
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success"
            style="float: right"
            @click="store('kt_save_and_add_service', 'SAA')"
            ref="kt_save_and_add_service"
          >
            <i class="fa fa-check"></i>Save and Add Another
          </button>
          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  CREATE_WHITELIST_EMAIL,
  FETCH_WHITELISTEMAIL_DETAILS
} from "@/core/services/store/actions.type";
import GoogleMap from "@/components/GoogleMap.vue";
// import { timeStamp } from "console";

export default {
  components: {
    GoogleMap
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      errors: [],
      isEdit: false,

      id: 0,
      email: "",
      company_name: "",
      contact_name: "",
      contact_phone_no: "",
      address1: "",
      address2: "",
      city: "",
      zipcode: "",
      country: "",
      state: "",
      lat: "",
      lng: "",

      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "WhiteList Email",
          route: "client.whitelistemail"
        },
        {
          id: 3,
          title: "Create New",
          route: ""
        }
      ]
    };
  },

  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.isEdit = this.$route.name == "client.whitelist.edit" ? true : false;

    if (this.isEdit) {
      this.fetchEmailDetails();
    }
  },

  methods: {
    clearForm() {
      (this.id = 0),
        (this.email = ""),
        (this.contact_name = ""),
        (this.company_name = ""),
        (this.contact_phone_no = ""),
        (this.address1 = ""),
        (this.address2 = ""),
        (this.zipcode = ""),
        (this.country = ""),
        (this.state = ""),
        (this.city = ""),
        (this.lat = ""),
        (this.lng = "");
    },
    openLoader(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    setLocationDragged(location) {
      this.lng = location.lng;
      this.lat = location.lat;
    },
    setLocation(location) {
      this.lng = location.lng;
      this.lat = location.lat;
      this.city = location.city.long_name;
      this.address1 = location.formatted_address;
      this.zipcode = location.postal_code.long_name;
      this.country = location.country.long_name;
      this.state = location.state.long_name;
    },
    setFullAddress(data) {
      this.address1 = data;
    },
    update(ref) {
      this.loadingButton(ref);
      // let img;
      // if (this.showImage) {
      //   img = this.image_crop_base64;
      // } else {
      //   img = "none";
      // }

      var _data = {
        id: this.id,
        email: this.email,
        contact_name: this.contact_name,
        company_name: this.company_name,
        contact_phone_no: this.contact_phone_no,
        address1: this.address1,
        address2: this.address2,
        zipcode: this.zipcode,
        country: this.country,
        state: this.state,
        city: this.city,
        lat: this.lat,
        lng: this.lng
      };

      this.$store
        .dispatch(CREATE_WHITELIST_EMAIL, _data)
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          this.$router.push({ name: "client.whitelistemail" });
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },

    store(ref, saveType) {
      var data = {
        id: this.id,
        email: this.email,
        contact_name: this.contact_name,
        company_name: this.company_name,
        contact_phone_no: this.contact_phone_no,
        address1: this.address1,
        address2: this.address2,
        zipcode: this.zipcode,
        country: this.country,
        state: this.state,
        city: this.city,
        lat: this.lat,
        lng: this.lng
      };
      this.$store
        .dispatch(CREATE_WHITELIST_EMAIL, data)
        .then(_data => {
          // console.log(_data);
          this.$toastr.s(_data.msg);
          this.closeBtnLoad(ref);

          //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
          if (saveType == "S") {
            this.$router.push({ name: "client.whitelistemail" });
          } else if (saveType == "SAA") {
            this.clearForm();
          }
        })
        .catch(err => {
          this.errors = err;
        });
    },
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.whitelistemail",
        params: { client_slug: client_slug }
      });
    },
    fetchEmailDetails() {
      var id = this.$route.params.id;
      this.breadCrumbs[2].title = "Edit";
      this.$store.dispatch(FETCH_WHITELISTEMAIL_DETAILS, id).then(data => {
        var data_ = data.data;

        this.id = data_.id;
        this.email = data_.email;

        this.contact_name = data_.contact_name;
        this.company_name = data_.company_name;
        this.contact_phone_no = data_.contact_phone_no;
        this.address1 = data_.address1;
        this.address2 = data_.address2;

        this.zipcode = data_.zipcode;
        this.country = data_.country;
        this.state = data_.state;
        this.city = data_.city;
        this.lat = data_.lat;
        this.lng = data_.lng;

        this.$refs.clientmap.defaultMarkerPosition(
          Number(data_.lat),
          Number(data_.lng)
        );
      });
    }
  }
};
</script>
